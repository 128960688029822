import React, { useRef, useEffect } from 'react'
// import { useStaticQuery, graphql } from 'gatsby'

import Flickity from 'react-flickity-component'
import styles from './chain-slider.module.sass'
import classNames from 'classnames'
// import Markdown from 'markdown-to-jsx'
// import BoxLink from '../CardItems/BoxLink'
// import Icon from '../Inlines/TechIcon'
import { debounce } from '../utils'

export default function ChainSlider({ children }) {
  const carouselInstance = useRef(null)
  const carouselOptions = {
    pageDots: false,
    cellAlign: 'left',
    contain: true,
    selectedAttraction: 0.1,
    friction: 0.8,
    // selectedAttraction: 0.025
    // friction: 0.28
    draggable: true,
    groupCells: true
  }

  useEffect(() => {
    const flkty = carouselInstance.current
    const resize = carouselInstance.current.resize
    flkty.resize = () => {
      flkty.element.classList.add(`flickity-resize`)
      resize.call(flkty)
      flkty.element.classList.remove(`flickity-resize`)
    }
    const debouncedHandleResize = debounce(function handleResize() {
      flkty.resize()
    }, 300)
    window.addEventListener('DOMContentLoaded', event => debouncedHandleResize)
    window.addEventListener('resize', debouncedHandleResize)
    document.fonts && document.fonts.ready.then(debouncedHandleResize)
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  return (
    <Flickity
      flickityRef={ref => {
        carouselInstance.current = ref
      }}
      className={styles.chainSlider}
      options={carouselOptions}
      reloadOnUpdate
    >
      {children}
    </Flickity>
  )
}
