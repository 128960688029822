import React from 'react'
import Markdown from 'markdown-to-jsx'
import styles from './numbers.module.sass'

export default function Numbers({ numbers }) {
  numbers ||= [
    { value: 50, unit: '+', text: 'IT **Professionals**' },
    { value: 9, unit: 'years', text: 'avg. staff **experience**' },
    // { value: 100, unit: '%', text: 'remote **work**' },
    // { value: 12, unit: '', text: 'global **Clients**' }
    { value: 60, unit: '+', text: 'happy **clients**' },
    { value: 10, unit: 'days', text: 'to **launch a team**' }
  ]

  return (
    <ul className={styles.numbers}>
      {numbers.map((number, i) => (
        <li key={i}>
          <span className={styles.value}>
            <em>{number.value}</em>
            {number.unit}
          </span>
          <span className={styles.text}>
            <Markdown>{number.text || ''}</Markdown>
          </span>
        </li>
      ))}
    </ul>
  )
}
