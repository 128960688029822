import React, { Suspense } from 'react'
import loadable, { lazy } from '@loadable/component'

import Loading from '../Inlines/Loading'

function LazySVG({ path }) {
  const ImportedSVG = lazy(() => import(`../../../static/images/animations/${path}`))

  return <ImportedSVG />
}

function LoadableSVG({ path }) {
  const ImportedSVG = loadable(() => import(`../../../static/images/animations/${path}`))
  return <ImportedSVG />
}

function Animation({ path, isActive = true }) {
  return (
    <figure className="position-relative">
      {isActive && (
        <Suspense fallback={<Loading />}>
          <LazySVG path={path} />
        </Suspense>
      )}
    </figure>
  )
}

Animation.displayName = 'Animation'

export default Animation
