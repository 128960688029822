import React from 'react'
import Flickity from 'react-flickity-component'
import classNames from 'classnames'
import Markdown from 'markdown-to-jsx'
import Image from '../Image'
import Accent from '../Inlines/Accent'
import startCase from 'lodash/startCase'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import styles from './project-phase.module.sass'
import Link from '../Link'
import CheckList from '../Elements/CheckList'

const Tag = ({ children }) => <span className={styles.phaseTag}>{children}</span>
const Service = ({ children, href }) => (
  <Link href={href} className={styles.phaseService}>
    {children}
  </Link>
)
const Label = ({ children, className }) => <span className={classNames(styles.label, className)}>{children}</span>
Label.displayName = 'Label'

export default function ProjectPhase({ content, info, image, counter, inverted, footer, customClass, services, images }) {
  return (
    <div className={classNames(styles.projectPhase, customClass, inverted && styles.phaseInverted)}>
      {content && (
        <Markdown
          className={classNames(styles.phaseContent)}
          children={content}
          options={{
            forceBlock: true,
            forceWrapper: true,
            overrides: {
              Accent,
              Tag,
              Label,
              Checks: { component: CheckList }
            }
          }}
        />
      )}

      {image && (
        <figure className={classNames(styles.phaseImage)}>
          <Image src={image} images={images} objectFit="contain" />
        </figure>
      )}

      {info && (
        <ul className={styles.phaseInfo}>
          {Object.keys(info).map(key => (
            <li key={key} className={styles[key]}>
              <strong>{startCase(key)}</strong>
              <Markdown>{info[key]}</Markdown>
            </li>
          ))}
        </ul>
      )}

      {footer && (
        <Markdown
          className={classNames(styles.phaseFooter)}
          children={footer}
          options={{
            forceBlock: true,
            forceWrapper: true,
            overrides: {
              Service,
              Image
            }
          }}
        />
      )}

      {services && (
        <Flickity
          className={styles.phaseSlider}
          options={{
            contain: true,
            cellAlign: 'left',
            pageDots: false,
            prevNextButtons: false,
            watchCSS: true,
            freeScroll: true
          }}
          reloadOnUpdate
          static
        >
          {services.map((service, i) => (
            <Service key={i} href={service.path}>
              {service.icon && <Image src={service.icon} />}
              <Markdown children={service.text} />
            </Service>
          ))}
        </Flickity>
      )}
      {counter && <span className={styles.phaseCounter}>{counter}</span>}
    </div>
  )
}

export const ProjectInfo = props => {
  return (
    <>
      {React.Children.map(props.children, child => {
        return (
          React.isValidElement(child) &&
          React.cloneElement(child, {
            className: classNames(styles.phaseInfo),
            children: React.Children.map(child.props.children, child => {
              return (
                React.isValidElement(child) &&
                React.cloneElement(child, {
                  className: classNames(styles[camelCase(get(child, 'props.children[0].props.children[0]'))]),
                  children: React.Children.toArray(child.props.children)
                })
              )
            })
          })
        )
      })}
    </>
  )
}
