import React, { useState, createContext, useContext } from 'react'
import ReactModal from 'react-modal'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import classNames from 'classnames'
import styles from './modal.module.sass'

export const ModalContext = createContext({})

export const ModalContextProvider = ({ children, set }) => {
  const [activeModal, setActiveModal] = useState('')

  return <ModalContext.Provider value={{ activeModal, setActiveModal, set }}>{children}</ModalContext.Provider>
}

export default function Modal({ name, children, className }) {
  const { activeModal, setActiveModal } = useContext(ModalContext)
  function afterOpenModal() {
    disableBodyScroll(document.querySelector('.ReactModal__Overlay'))
  }

  function closeModal() {
    enableBodyScroll(document.querySelector('.ReactModal__Overlay'))
    setActiveModal('')
  }

  return (
    <ReactModal
      overlayClassName={styles.modalOverlay}
      className={classNames(styles.modalContent, className)}
      isOpen={activeModal && activeModal.startsWith(name)}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="modal"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      {children}
      <button className={styles.closeButton} data-dismiss="modal" aria-label="Close" onClick={closeModal}>
        Close
      </button>
    </ReactModal>
  )
}
