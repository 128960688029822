import React from 'react'
import BulbIcon from '../../../static/uploads/icons/icon_bulb.inline.svg'
import TeamExtensionIcon from '../../../static/uploads/icons/icon_team_extension.inline.svg'
import EndToEndIcon from '../../../static/uploads/icons/icon_end-to-end.inline.svg'
import ConsultingIcon from '../../../static/uploads/icons/icon_consulting.inline.svg'
import CvIcon from '../../../static/uploads/icons/icon_cv.inline.svg'
import CalendarIcon from '../../../static/uploads/icons/icon_calendar.inline.svg'
import DescribeIcon from '../../../static/uploads/icons/icon_describe.inline.svg'
import EngineeringIcon from '../../../static/uploads/icons/icon_engineering.inline.svg'

const icons = {
  'team-extension': <TeamExtensionIcon />,
  'end-to-end': <EndToEndIcon />,
  consulting: <ConsultingIcon />,
  bulb: <BulbIcon />,
  cv: <CvIcon />,
  calendar: <CalendarIcon />,
  engineering: <EngineeringIcon />,
  describe: <DescribeIcon />
}

const Icon = ({ name }) => {
  return <div className='icon-wrapper'>{icons[name]}</div>
}

export default Icon
