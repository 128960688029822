import React, { useRef, useEffect } from 'react'
import styles from './fact-box.module.sass'

export default function FactBox({ children }) {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      const textNode = ref.current.lastChild
      const range = document.createRange()
      range.selectNodeContents(textNode)
      const rects = range.getClientRects()
      if (rects.length === 0) {
        return
      }
      const maxWidth = Array.from(rects).reduce((maxWidth, rect) => Math.max(maxWidth, rect.width), 0)
      ref.current.style.setProperty('--textWidth', `${maxWidth}px`)
    }
  }, [children])

  return (
    <div className={styles.factBox} ref={ref}>
      {children}
    </div>
  )
}
