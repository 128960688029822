export const isIgnoredLocale = (languages = global.navigator.languages) =>
  (
    (languages || [])
      .join(',')
      .toLowerCase()
      .match(
        /af|as|az|be|by|bg|ce|fa|fil|hi|hu|gu|zw|ee|id|in|bn|ka|kk|km|ki|kn|kok|lv|lt|ml|mk|mr|mas|mer|ng|ne|pa|pk|pl|ph|pt|ro|ru|sa|si|sk|sr|sg|so|ta|te|th|ti|ua|ug|ur|uz|vi|za|zu|zw/
      ) || []
  ).length > 0

export const isSpam = (message = '', languages = global.navigator.languages) => {
  const dirtySpam = /sex|hot girls|porn|fuck|^invest|http[^\s]+\.(ru|ua)/i
  const seoSpam = /guest post|seo services|how your website|charge you|our rate/i
  const linkSpam = /http[^\s]+$/i
  const match = regex => ((message || '').trim().match(regex) || []).length > 0

  if (match(dirtySpam)) {
    return true
  } else if (match(seoSpam)) {
    return true
  } else if (match(linkSpam)) {
    return isIgnoredLocale(languages)
  } else {
    return false
  }
}
