import React from 'react'
import loadable from '@loadable/component'

import Markdown from 'markdown-to-jsx'
import styles from './text-section.module.sass'
import classNames from 'classnames'
import Numbers from '../Blocks/Numbers'
import GridGallery from '../Wrappers/GridGallery'
import GridBox from '../Wrappers/GridBox'
import NumberBox from '../Boxes/NumberBox'
import Modal, { ModalContextProvider } from '../Modals/Modal'
import ProfileSlider from '../Blocks/ProfileSlider'
import Button from '../Inlines/Button'
import Accent from '../Inlines/Accent'
import Image from '../Image'
import { ProjectInfo } from '../Blocks/ProjectPhase'
import Animation from '../Animations/Animation'
import CheckList from '../Elements/CheckList'
import { parameterize } from '../utils'
import ClutchWidget from '../Blocks/ClutchWidget'
import ClutchBox from '../Boxes/ClutchBox'
import FactBox from '../Boxes/FactBox'
import Logos from '../Blocks/Logos'
import ChainSlider from '../Wrappers/ChainSlider'
import BoxLink from '../CardItems/BoxLink'
import TestimonialCard from '../CardItems/TestimonialCard'
import CaseGrid from '../Wrappers/CaseGrid'
import CaseCard from '../CardItems/CaseCard'
import AsideBanner from '../Blog/AsideBanner'
import PostBox from '../Blog/PostBox'
import HoverBox from '../Elements/HoverBox'
import ExpandableGallery from '../Wrappers/ExpandableGallery'
// import { parseInlineStyle } from '../utils'

// import ModalVideoButton from '../Modals/ModalVideoButton'
const LazyModalVideoButton = loadable(() => import('../Modals/ModalVideoButton'))
const ModalVideoButton = props => <LazyModalVideoButton {...props} />
// import ProfileItem from '../CardItems/ProfileItem'
const LazyProfileItem = loadable(() => import('../CardItems/ProfileItem'))
const ProfileItem = props => <LazyProfileItem {...props} />
// import SequenceSlider from '../Wrappers/SequenceSlider'
const LazySequenceSlider = loadable(() => import('../Wrappers/SequenceSlider'))
const SequenceSlider = props => <LazySequenceSlider {...props} />

const LazyDimSlider = loadable(() => import('../Wrappers/DimSlider'))
const DimSlider = props => <LazyDimSlider {...props} />

const Box = ({ children, counter }) => (
  <div className={styles.box} data-counter={counter}>
    {children}
  </div>
)

export const Slider = props => (
  <>
    {React.Children.map(props.children, child => {
      return (
        React.isValidElement(child) && (
          <SequenceSlider>
            {React.Children.map(child.props.children, child => {
              return React.isValidElement(child) && <div>{child.props.children}</div>
            })}
          </SequenceSlider>
        )
      )
    })}
  </>
)

const Base = ({ children }) => <div className={styles.base}>{children}</div>
const Icon = props => <Image className={styles.techIcon} src={`/images/technologies/${parameterize(props.name)}.svg`} alt={props.name} />
const FakeLink = ({ children }) => <span className={styles.fakeLink}>{children}</span>
const Inline = ({ children, className }) => <span className={classNames(styles.inline, className)}>{children}</span>
const Label = ({ children, className }) => <span className={classNames(styles.label, className)}>{children}</span>
Label.displayName = 'Label'
const HeadQuote = ({ children }) => <h2 className={styles.headQuote}>{children}</h2>
const Figure = ({ children }) => <div className={styles.figure}>{children}</div>
const StyleBox = ({ children, className, customClass, style }) => (
  <div className={classNames(styles[className], customClass)} style={style}>
    {children}
  </div>
)

export const Technologies = props => (
  <>
    {React.Children.map(props.children, child => {
      return (
        React.isValidElement(child) &&
        React.cloneElement(child, {
          className: classNames(styles.technologies, props.className),
          children: React.Children.map(child.props.children, child => {
            return (
              React.isValidElement(child) &&
              React.cloneElement(child, {
                className: classNames(styles.technology),
                children: React.Children.toArray(child.props.children).concat(<FakeLink children="Learn More" />)
              })
            )
          })
        })
      )
    })}
  </>
)

// export const SetContext = createContext({})

const ModalSet = ({ children, set }) => {
  return (
    <ModalContextProvider set={set}>
      {children}
      <Modal name={set}>
        <ProfileSlider>{children}</ProfileSlider>
      </Modal>
      {/* <ProfileSlider customClass="block">{children}</ProfileSlider> */}
    </ModalContextProvider>
  )
}

// const Checks = ({ children }) => <div className={styles.checks}>{children}</div>
const OverlapGallery = ({ children }) => <div className={styles.overlapGallery}>{children}</div>
const PointList = ({ children }) => <ul className={styles.pointList}>{children}</ul>
const Point = ({ children, icon }) => (
  <li className={styles.pointItem}>
    {icon && <Image src={`/images/icons/${icon}.svg`} alt={icon} wrapper />}
    {children}
  </li>
)
const NumberList = ({ children }) => <ul className={styles.numberList}>{children}</ul>
const NumberItem = ({ value = '', unit = '', text = '', className }) => (
  <li className={styles.numberItem}>
    <Markdown className="value" options={{ forceWrapper: true }}>
      {value + unit}
    </Markdown>{' '}
    <Markdown className="text" options={{ forceWrapper: true }}>
      {text}
    </Markdown>
  </li>
)

export default function TextSection({ columns, classes, background, id, images }) {
  return (
    <section id={id} className={classNames(styles.textSection, classes)}>
      <div className="container">
        <div className="row">
          {columns.map((column, i) => (
            <Markdown
              key={i}
              className={classNames(styles.column, column.classes)}
              options={{
                forceBlock: true,
                forceWrapper: true,
                overrides: {
                  Numbers,
                  NumberList,
                  NumberItem,
                  Button,
                  ModalVideoButton,
                  GridGallery,
                  GridBox,
                  NumberBox,
                  OverlapGallery,
                  Image: {
                    component: Image,
                    props: { wrapper: true, images: images, objectFit: 'contain' }
                  },
                  ModalSet,
                  ProfileItem,
                  Accent,
                  Box,
                  Checks: { component: CheckList },
                  Base,
                  Technologies,
                  Icon,
                  HeadQuote,
                  Figure,
                  PointList,
                  Point,
                  ClutchWidget,
                  ClutchBox,
                  FactBox,
                  Info: { component: ProjectInfo },
                  Slider,
                  Animation,
                  Inline,
                  Logos,
                  StyleBox,
                  HoverBox,
                  ChainSlider,
                  BoxLink,
                  DimSlider,
                  Testimonial: { component: TestimonialCard },
                  Label,
                  CaseGrid,
                  CaseCard,
                  AsideBanner,
                  PostBox,
                  ExpandableGallery
                }
              }}
            >
              {column.content}
            </Markdown>
          ))}
        </div>
        {background && (
          <Markdown
            children={background}
            options={{
              overrides: {
                Image: {
                  component: props => {
                    return (
                      <Image
                        {...props}
                        className={classNames(props.className ? styles[props.className] : styles.sectionBg, props.customClass)}
                        images={images}
                        objectFit={props.className === 'fitImage' && 'contain'}
                        wrapper
                      />
                    )
                  }
                }
              }
            }}
          />
        )}
      </div>
    </section>
  )
}
