import React from 'react'
import styles from './check-list.module.sass'
import classNames from 'classnames'

export default function CheckList(props) {
  let hasList = false
  const children = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      switch (child.type.displayName || child.type) {
        case 'List':
        case 'ul':
        case 'ol':
          hasList = true
          return React.cloneElement(child, {
            className: `${styles.checkList}${React.Children.count(child.props.children) % 3 === 0 ? ' dense' : ''}`,
            children: child.props.children
          })
        default:
          return child
      }
    }
  })

  return hasList ? children : <div className={classNames(styles.checks, props.customClass)}>{children}</div>
}
