import React from 'react'
import Image from '../Image'
import Link from '../Link'
import styles from './post-box.module.sass'
import { parameterize } from '../utils'

export default function PostBox({ path, title, readingTime, author }) {
  return (
    <div className={styles.postBox}>
      <h3>
        <Link to={path}>{title}</Link>
      </h3>

      <span className={styles.readingTime}>{readingTime + ' read'}</span>

      <figure className={styles.postAuthor}>
        <Image
          className={styles.authorImage}
          src={'/images/profiles/' + parameterize(author) + '/' + parameterize(author) + '-avatar.png'}
          width={56}
          height={56}
          wrapper={false}
        />

        <figcaption className={styles.authorName}>{author}</figcaption>
      </figure>

      {/* <Image wrapper className={styles.bannerImage} width={935} height={670}  /> */}
    </div>
  )
}
