import React from 'react'
import classNames from 'classnames'
import Image from '../Image'
import styles from './logos.module.sass'

export default function Logos({ logos, className, customStyles, children }) {
  const modifiedChildren = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child) && child.type === Image) {
      return React.cloneElement(child, {
        wrapper: true,
        className: classNames(child.props.className, styles.logosItem)
      })
    }
    return child
  })
  return (
    <div className={classNames(className || styles.logosBlock, customStyles)}>
      {(logos || []).map((logo, i) => (
        <Image src={logo} wrapper className={styles.logosItem} />
      ))}
      {modifiedChildren}
    </div>
  )
}
