import React, { useRef, useContext } from 'react'
import ProfileBlock from '../CardItems/ProfileBlock'
import Flickity from 'react-flickity-component'
import { ModalContext } from '../Modals/Modal'
import kebabCase from 'lodash/kebabCase'
import get from 'lodash/get'
import classNames from 'classnames'
import styles from './profile-slider.module.sass'

export default function ProfileSlider({ children, customClass }) {
  const { activeModal } = useContext(ModalContext)
  const initialIndex =
    children.findIndex(child => kebabCase(get(child, 'props.id', child.id || get(child, 'props.name', child.name))) === (activeModal.split('_')[1] || '')) || 0
  const profilesInstance = useRef(null)
  const profilesOptions = {
    initialIndex: initialIndex,
    pageDots: false,
    cellAlign: 'left',
    contain: true,
    selectedAttraction: 0.2,
    friction: 0.8,
    draggable: true,
    adaptiveHeight: true
  }

  return (
    <Flickity
      flickityRef={carouselRef => {
        profilesInstance.current = carouselRef
      }}
      className={classNames(styles.profileSlider, styles.flickityCarousel, customClass)}
      options={profilesOptions}
    >
      {children.map((child, i) => {
        return <ProfileBlock key={i} {...(child.props || child)} eager={i === initialIndex} />
      })}
    </Flickity>
  )
}
