import React, { useEffect, useRef } from 'react'
import { getNthOfTypeIndex } from '../utils'
import styles from './hover-box.module.sass'

export default function HoverBox({ children }) {
  const ref = useRef(null)

  useEffect(() => {
    ref.current.addEventListener('mouseover', () => {
      ref.current.closest('section').classList.add('has-box-hover', 'box-index-' + getNthOfTypeIndex(ref.current))
      ref.current.closest('section').dataset.hoverIndex = getNthOfTypeIndex(ref.current)
    })
    ref.current.addEventListener('mouseleave', () => {
      ref.current.closest('section').classList.remove('has-box-hover', 'box-index-' + getNthOfTypeIndex(ref.current))
      delete ref.current.closest('section').dataset.hoverIndex
    })
  }, [])

  return (
    <div ref={ref} className={styles.hoverBox}>
      {children}
    </div>
  )
}
