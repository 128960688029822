import React from 'react'
import Markdown from 'markdown-to-jsx'
import { Link } from 'gatsby'
import classNames from 'classnames'
import Icon from '../Icon'
import Image from '../Image'
import ArrowRightIcon from '../../../static/uploads/icons/icon_arrow-right.inline.svg'
import Accent from '../Inlines/Accent'
import styles from './services-section.module.sass'

export default function ServicesSection({ header, services, classes }) {
  return (
    <section className={classNames(styles.servicesSection, classes)}>
      <div className="container">
        {header && <Markdown className={styles.sectionHeader} children={header} options={{ forceBlock: true, forceWrapper: true, overrides: { Accent } }} />}
        <div className={styles.sectionContent}>
          <div className="row">
            {services.map((service, i) => (
              <div className="col" key={i}>
                {service.link ? (
                  <Link to={service.link} className="link-unstyled">
                    <div className={classNames('box', service.classes)}>
                      {service.icon && <Icon name={service.icon} />}
                      <div className="box-content">
                        <Markdown children={service.text} />
                        <p className="box-link">
                          learn more
                          <ArrowRightIcon />
                        </p>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div className={classNames('box', service.classes)}>
                    {service.icon && <Icon name={service.icon} />}
                    <div className="box-content">
                      <Markdown
                        children={service.text}
                        options={{
                          wrapper: function none({ children }) {
                            return children
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <Image className={styles.sectionBg} src="/ui/services-section-bg.svg" wrapper />
      </div>
    </section>
  )
}
