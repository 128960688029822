import React, { useRef, useEffect } from 'react'
import * as styles from './expandable-gallery.module.sass'
import { getNthOfTypeIndex } from '../utils'

export default function ExpandableGallery({ children }) {
  return (
    <div className={styles.expandableGallery}>
      {/* + ' has-expandable-images' */}
      {children}
      {/* {React.Children.map(children, child => {
        return (
          <ExpandableFigure>
            {React.cloneElement(child, {
              wrapper: false
            })}
          </ExpandableFigure>
        )
      })} */}
    </div>
  )
}

const ExpandableFigure = ({ children }) => {
  const ref = useRef(null)

  useEffect(() => {
    const figure = ref.current
    const parentSection = figure.closest('section')

    figure.addEventListener('transitionend', e => {
      if (e.propertyName != 'transform') {
        return
      }
      figure.classList.toggle('is-active', getNthOfTypeIndex(figure) === ~~parentSection.dataset.hoverIndex)
      figure.classList.toggle('is-inactive', !!parentSection.dataset.hoverIndex && getNthOfTypeIndex(figure) !== ~~parentSection.dataset.hoverIndex)
    })
  }, [])

  return (
    <figure ref={ref} className="is-expandable">
      {children}
    </figure>
  )
}
