import React from 'react'
import styles from './box-link.module.sass'

const FakeLink = ({ children }) => <span className={styles.fakeLink}>{children}</span>

const hasAnchor = children => {
  if (!children) return false

  let result = false

  React.Children.forEach(children, child => {
    if (result) return // If result is already true, break the loop

    if (React.isValidElement(child)) {
      if (child.type === 'a') {
        result = true
        return // Break out of loop
      } else if (child.props.children) {
        result = hasAnchor(child.props.children)
      }
    }
  })

  return result
}

export default function BoxLink({ children }) {
  return (
    <div className={styles.boxLink}>
      {children}
      {hasAnchor(children) && <FakeLink>Learn More</FakeLink>}
    </div>
  )
}
