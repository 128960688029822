import React, { useState, useEffect } from 'react'
import PostCard from '../CardItems/PostCard'
import Accent from '../Inlines/Accent'
import ArrowIcon from '../../../static/ui/arrow-icon.inline.svg'
import classNames from 'classnames'
import styles from './profile-posts.module.sass'

export default function ProfilePosts({ allPosts, featuredPost, classes, title = 'My point of view' }) {
  const loadCount = 18;
  // State for the posts
  const [posts, setPosts] = useState([...allPosts.slice(0, loadCount)])
  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)
  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allPosts.length > loadCount)
  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }
  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = posts.length
      const isMore = currentLength < allPosts.length
      const nextResults = isMore ? allPosts.slice(currentLength, currentLength + loadCount) : []
      setPosts([...posts, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore, posts, allPosts])
  //Check if there is more
  useEffect(() => {
    const isMore = posts.length < allPosts.length
    setHasMore(isMore)
  }, [posts.length, allPosts.length])

  return (
    <section className={classNames(styles.profilePosts, classes)}>
      <div className="container">
        <h2 className={styles.sectionTitle}>{title}</h2>
        <Accent />
        <div className={styles.sectionContent}>
          <div className={styles.postGrid}>
            {((featuredPost && [featuredPost]) || []).concat(posts).map((post, i) => (
              <PostCard key={i} {...post} />
            ))}
          </div>
          {hasMore && (
            <button className={styles.loadMore} onClick={handleLoadMore}>
              Load More
              <ArrowIcon className="ml-2" />
            </button>
          )}
        </div>
      </div>
    </section>
  )
}
