import React from 'react'
import Image from '../Image'
import { useStaticQuery, graphql } from 'gatsby'
import Markdown from 'markdown-to-jsx'
import styles from './profile-block.module.sass'

export default function ProfileBlock(profile) {
  const { people } = useStaticQuery(graphql`
    query peopleQuery {
      people: allPeopleYaml(limit: 1000) {
        nodes {
          id
          name
          summary
          image
          bio
        }
      }
    }
  `)

  const person = people.nodes.find(person => person.id === profile.id)

  const { name, position, image, background, summary, bio } = Object.assign(person || {}, profile)

  return (
    <article className={styles.profileBlock}>
      <div className={styles.profileHeader}>
        <h3 className={styles.profileName}>{name}</h3>
        <span className={styles.profilePosition}>{position}</span>
      </div>
      <figure className={styles.profileImage}>
        <Image src={image || background} alt={name} />
      </figure>

      <Markdown
        className={styles.profileBody}
        children={bio || summary || ''}
        options={{
          forceWrapper: true,
          forceBlock: true
          //   overrides: {
          //     a: {
          //       props: {
          //         className: styles.viewProfile
          //       }
          //     }
          //   }
        }}
      ></Markdown>
      {/* + path ? `<p><a href='${path}' className='${styles.viewProfile}'>view profile</a><p>` : ''} */}
    </article>
  )
}
