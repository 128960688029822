import React from 'react'
import Markdown from 'markdown-to-jsx'
import classNames from 'classnames'
import styles from './number-box.module.sass'

export default function NumberBox({ value = '', unit = '', text = '', highlight, children }) {
  return (
    <div className={classNames(styles.numberBox, { highlight: highlight })}>
      <div className={styles.boxInner}>
        <Markdown className="value" options={{ forceWrapper: true }}>
          {value + unit}
        </Markdown>{' '}
        <Markdown className="text" options={{ forceWrapper: true }}>
          {text}
        </Markdown>
      </div>
      {children}
    </div>
  )
}
